const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [0, 12, 12, 0],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
	 var resize1 = function () {
	   brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
	   brd1.fullUpdate();};
  window.addEventListener("resize", resize1);
  brd1.options.layer['image'] =14;
	brd1.create('text', [6, 11.5, 'Pie Chart'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  var type1 = 2;
  var type2 = 3;
  var type3 = 4
  var type4 = 1
  var type5 = 2;
  var c1=0, c2=0, c3=0, c4=0, c5=0;
  var dataArr=[function(){return type1.valueOf()+c1}, function(){return type2.valueOf()+c2}, function(){return type3.valueOf()+c3}, function(){return type4.valueOf()+c4}, function(){return type5.valueOf()+c5}];
  var bulldog=[];
  var doberman=[];
  var greyhound=[];
  var dalmatian=[];
  var sharpei =[];
  //var charts=[];
  var a=1;
  var i=1, j=1, k=1, l=1, m=1;
  var ini =function(){
  brd1.removeObject(bulldog);
  brd1.removeObject(doberman);
  brd1.removeObject(greyhound);
  brd1.removeObject(dalmatian);
  brd1.removeObject(sharpei);
  //brd1.removeObject(charts);
  }
  var bd = function(){
  for(i=1;i<=type1;i++)
   {
   bulldog[i-1] =brd1.create('image', ['/assets/bulldog.svg', [2.55, 9.75-a*(i-1)],[0.9, 0.9]], {opacity:1, fixed:true});
   bulldog[i-1].setLabel('Bulldog')
   bulldog[i-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
   bulldog[i-1].on('over', function () {this.label.setAttribute({visible:true});});
   bulldog[i-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }
  var dm = function(){
  for(j=1;j<=type2;j++)
   {
   doberman[j-1] =brd1.create('image', ['/assets/doberman.svg', [4.05, 9.75-a*(j-1)],[0.9, 0.9]], {opacity:1, fixed:true});
   doberman[j-1].setLabel('Doberman')
   doberman[j-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
   doberman[j-1].on('over', function () {this.label.setAttribute({visible:true});});
   doberman[j-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }
  var gh = function(){
  for(k=1;k<=type3;k++)
   {
   greyhound[k-1] =brd1.create('image', ['/assets/greyhound.svg', [5.55, 9.75-a*(k-1)],[0.9, 0.9]], {opacity:1, fixed:true});
   greyhound[k-1].setLabel('Greyhound')
   greyhound[k-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
   greyhound[k-1].on('over', function () {this.label.setAttribute({visible:true});});
   greyhound[k-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }
  var dmt = function(){
  for(l=1;l<=type4;l++)
   {
   dalmatian[l-1] =brd1.create('image', ['/assets/dalmatian.svg', [7.05, 9.75-a*(l-1)],[0.9, 0.9]], {opacity:1, fixed:true});
   dalmatian[l-1].setLabel('Dalmatian')
   dalmatian[l-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
   dalmatian[l-1].on('over', function () {this.label.setAttribute({visible:true});});
   dalmatian[l-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }
  var sp = function(){
  for(m=1;m<=type5;m++)
   {
   sharpei[m-1] =brd1.create('image', ['/assets/shar-pei.svg', [8.55, 9.75-a*(m-1)],[0.9, 0.9]], {opacity:1, fixed:true});
   sharpei[m-1].setLabel('Shar-Pei')
   sharpei[m-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
   sharpei[m-1].on('over', function () {this.label.setAttribute({visible:true});});
   sharpei[m-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }

var charts =brd1.create('chart', [dataArr], {highlightOnSector:true, highlightStrokeColor:'black',
         highlightBySize:true, fillOpacity:1.0,chartStyle:'pie',center:[6,3], colorArray: ['#0F408D','#6F1B75','#CA147A','#DA2228','#E8801B'],
         highlightColorArray:['#FFFFFF','#F9DF82','#F7FA7B','#B0D990','#69BF8E'],label:{display:'internal',CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}}, strokeColor:'black', radius:2, strokeWidth:3});
//charts.label.setAttribute({visible:false2, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  bd(); dm();gh();dmt();sp();
  var reload = brd1.create('image', ['/assets/shuffle.svg', [10.25,8.4],[1.2, 1.2]], {opacity:1, fixed:true});
  reload.setLabel('Tap to Shuffle')
  reload.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  reload.on('over', function () {this.label.setAttribute({visible:true});});
  reload.on('out', function () {this.label.setAttribute({visible:false});});
  //
  brd1.create('text', [()=>6+2.75*Math.cos(0.5*type1*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin(0.5*type1*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Bulldog ('+type1.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  brd1.create('text', [()=>6+2.75*Math.cos((type1+0.5*type2)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+0.5*type2)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Doberman ('+type2.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()) +')' ],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+0.5*type3)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+0.5*type3)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Grehound ('+type3.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+type3+0.5*type4)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+type3+0.5*type4)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Dalmatian ('+type4.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+type3+type4+0.5*type5)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+type3+type4+0.5*type5)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Shar-Pie ('+type5.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()) +')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
//
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>1});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(2*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(2*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>2});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(3*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(3*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>3});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(4*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(4*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>4});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(5*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(5*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>5});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(6*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(6*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>6});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(7*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(7*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>7});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(8*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(8*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>8});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(9*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(9*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>9});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(10*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(10*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>10});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(11*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(11*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>11});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(12*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(12*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>12});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(13*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(13*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>13});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(14*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(14*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>14});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(15*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(15*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>15});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(16*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(16*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>16});
  brd1.create('segment', [[6,3], [()=>6+2*Math.cos(17*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(17*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
  {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>17});
//
//  var rad =function(){
//  for(r=1; r<=(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()); r++)
//  brd1.create('segment', [[6,3], [6+2*Math.cos((r-1)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), 3+2*Math.sin((r-1)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
///  {strokeColor:'black', strokeWidth:1, dash:2});}
//rad();
  reload.on('down', function(){ini();type1=Math.round(Math.random()*3)+1; type2=Math.round(Math.random()*4)+1; type3=Math.round(Math.random()*4)+1;type4=Math.round(Math.random()*2)+1;type5=Math.round(Math.random()*3)+1;
   i=1;j=1;k=1;l=1;m=1;
    bd();
    dm();
    gh();
    dmt();
    sp();
  });
  //brd1.create('text', [9, -0.5, 'Breed of Dog'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}});
  //brd1.create('text', [-0.5, 5, 'Number of Dogs'],{display:'internal', rotate:90, anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}});
  brd1.create('text', [3, 10.85, 'Bulldog'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  brd1.create('text', [4.5, 10.85, 'Doberman'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  brd1.create('text', [6, 10.85, 'Greyhound'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  brd1.create('text', [7.5, 10.85, 'Dalmatian'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  brd1.create('text', [9, 10.85, 'Shar-Pei'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});

    },
    box2: function () {
  	 JXG.Options.board.minimizeReflow = 'none';
     var brd2 = JXG.JSXGraph.initBoard('jxgbox2',{boundingbox: [0, 12, 12, 0],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
   	 var resize1 = function () {
   	   brd2.resizeContainer(brd2.containerObj.clientWidth, brd2.containerObj.clientHeight, true);
   	   brd2.fullUpdate();};
     window.addEventListener("resize", resize1);
    brd2.options.layer['image'] =14;
   	brd2.create('text', [6, 11.5, 'Pie Chart'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd2.canvasWidth/800.)}, fixed:true});
///////////////////////////
    var inp1 =brd2.create('input', [2, 6, 0, ''], {cssStyle: 'fontFamily:Oswald;width:8.333%;height:8.333%', fontSize:function(){return Math.round(18*brd2.canvasWidth/800.)},fixed:true, maxlength:2});
    var inp2 =brd2.create('input', [2, 5, 0, ''], {cssStyle: 'fontFamily:Oswald;width:8.333%;height:8.333%', fontSize:function(){return Math.round(18*brd2.canvasWidth/800.)},fixed:true, maxlength:2});
    var inp3 =brd2.create('input', [2, 4, 0, ''], {cssStyle: 'fontFamily:Oswald;width:8.333%;height:8.333%', fontSize:function(){return Math.round(18*brd2.canvasWidth/800.)},fixed:true, maxlength:2});
    var inp4 =brd2.create('input', [2, 3, 0, ''], {cssStyle: 'fontFamily:Oswald;width:8.333%;height:8.333%', fontSize:function(){return Math.round(18*brd2.canvasWidth/800.)},fixed:true, maxlength:2});
    var inp5 =brd2.create('input', [2, 2, 0, ''], {cssStyle: 'fontFamily:Oswald;width:8.333%;height:8.333%', fontSize:function(){return Math.round(18*brd2.canvasWidth/800.)},fixed:true, maxlength:2});
/////////////////////////
    brd2.create('text', [0.5, 6, 'Bulldog :'],{display:'internal',anchorY:'bottom', anchorX:'left', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd2.canvasWidth/800.)}});
    brd2.create('text', [0.5, 5, 'Doberman :'],{display:'internal',anchorY:'bottom', anchorX:'left', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd2.canvasWidth/800.)}});
    brd2.create('text', [0.5, 4, 'Greyhound :'],{display:'internal',anchorY:'bottom', anchorX:'left', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd2.canvasWidth/800.)}});
    brd2.create('text', [0.5, 3, 'Dalmatian :'],{display:'internal',anchorY:'bottom', anchorX:'left', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd2.canvasWidth/800.)}});
    brd2.create('text', [0.5, 2, 'Shar-Pei :'],{display:'internal',anchorY:'bottom', anchorX:'left', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd2.canvasWidth/800.)}});

     var type1 = 2;
     var type2 = 3;
     var type3 = 4
     var type4 = 1
     var type5 = 2;
    // var dataArr=[function(){return type1.valueOf()}, function(){return type2.valueOf()}, function(){return type3.valueOf()}, function(){return type4.valueOf()}, function(){return type5.valueOf()}];
     var dataArr=[function(){return inp1.Value();}, function(){return inp2.Value();}, function(){return inp3.Value();}, function(){return inp4.Value();}, function(){return inp5.Value();}];
     var bulldog=[];
     var doberman=[];
     var greyhound=[];
     var dalmatian=[];
     var sharpei =[];
     //var charts=[];
     var a=1;
     var i=1, j=1, k=1, l=1, m=1;
     var ini =function(){
     brd2.removeObject(bulldog);
     brd2.removeObject(doberman);
     brd2.removeObject(greyhound);
     brd2.removeObject(dalmatian);
     brd2.removeObject(sharpei);
     //brd2.removeObject(charts);
     }
     var bd = function(){
     for(i=1;i<=type1;i++)
      {
      bulldog[i-1] =brd2.create('image', ['/assets/bulldog.svg', [2.55, 9.75-a*(i-1)],[0.9, 0.9]], {opacity:1, fixed:true});
      bulldog[i-1].setLabel('Bulldog')
      bulldog[i-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      bulldog[i-1].on('over', function () {this.label.setAttribute({visible:true});});
      bulldog[i-1].on('out', function () {this.label.setAttribute({visible:false});});
      }
     }
     var dm = function(){
     for(j=1;j<=type2;j++)
      {
      doberman[j-1] =brd2.create('image', ['/assets/doberman.svg', [4.05, 9.75-a*(j-1)],[0.9, 0.9]], {opacity:1, fixed:true});
      doberman[j-1].setLabel('Doberman')
      doberman[j-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      doberman[j-1].on('over', function () {this.label.setAttribute({visible:true});});
      doberman[j-1].on('out', function () {this.label.setAttribute({visible:false});});
      }
     }
     var gh = function(){
     for(k=1;k<=type3;k++)
      {
      greyhound[k-1] =brd2.create('image', ['/assets/greyhound.svg', [5.55, 9.75-a*(k-1)],[0.9, 0.9]], {opacity:1, fixed:true});
      greyhound[k-1].setLabel('Greyhound')
      greyhound[k-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      greyhound[k-1].on('over', function () {this.label.setAttribute({visible:true});});
      greyhound[k-1].on('out', function () {this.label.setAttribute({visible:false});});
      }
     }
     var dmt = function(){
     for(l=1;l<=type4;l++)
      {
      dalmatian[l-1] =brd2.create('image', ['/assets/dalmatian.svg', [7.05, 9.75-a*(l-1)],[0.9, 0.9]], {opacity:1, fixed:true});
      dalmatian[l-1].setLabel('Dalmatian')
      dalmatian[l-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      dalmatian[l-1].on('over', function () {this.label.setAttribute({visible:true});});
      dalmatian[l-1].on('out', function () {this.label.setAttribute({visible:false});});
      }
     }
     var sp = function(){
     for(m=1;m<=type5;m++)
      {
      sharpei[m-1] =brd2.create('image', ['/assets/shar-pei.svg', [8.55, 9.75-a*(m-1)],[0.9, 0.9]], {opacity:1, fixed:true});
      sharpei[m-1].setLabel('Shar-Pei')
      sharpei[m-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      sharpei[m-1].on('over', function () {this.label.setAttribute({visible:true});});
      sharpei[m-1].on('out', function () {this.label.setAttribute({visible:false});});
      }
     }
   var charts =brd2.create('chart', [dataArr], {highlightOnSector:true, highlightStrokeColor:'black',
            highlightBySize:true, fillOpacity:1.0,chartStyle:'pie',center:[8,3], colorArray: ['#0F408D','#6F1B75','#CA147A','#DA2228','#E8801B'],
            highlightColorArray:['#FFFFFF','#F9DF82','#F7FA7B','#B0D990','#69BF8E'],
          /*  labels:[()=>'Bulldog: '+inp1.Value()+'/'+(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp4.Value()),
             ()=>'Doberman: '+ type2.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()),
             ()=>'Greyhound: '+ type3.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()),
             ()=>'Dalmatian: '+ type4.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()),
             ()=>'Shar-Pei: '+ type5.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())], */
            label:{display:'internal',anchorX:'left', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}}, strokeColor:'black', radius:2, strokeWidth:2});
   //charts.label.setAttribute({visible:false2, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
     bd(); dm();gh();dmt();sp();
     brd2.create('text', [()=>8+2.75*Math.cos(0.5*inp1.Value()*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())), ()=>3+2.75*Math.sin(0.5*inp1.Value()*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())),
        ()=>'Bulldog ('+inp1.Value()+'/'+(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())+')'],{visible:()=>inp1.Value()>0, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
     brd2.create('text', [()=>8+2.75*Math.cos((1*inp1.Value()+0.5*inp2.Value())*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())), ()=>3+2.75*Math.sin((1*inp1.Value()+0.5*inp2.Value())*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())), ()=>'Doberman ('+inp2.Value()+'/'+(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value()) +')' ],{visible:()=>inp2.Value()>0, display:'internal', anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
     brd2.create('text', [()=>8+2.75*Math.cos((1*inp1.Value()+1*inp2.Value()+0.5*inp3.Value())*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())), ()=>3+2.75*Math.sin((1*inp1.Value()+1*inp2.Value()+0.5*inp3.Value())*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())), ()=>'Grehound ('+inp3.Value()+'/'+(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())+')'],{visible:()=>inp3.Value()>0, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
     brd2.create('text', [()=>8+2.75*Math.cos((1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+0.5*inp4.Value())*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())), ()=>3+2.75*Math.sin((1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+0.5*inp4.Value())*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())), ()=>'Dalmatian ('+inp4.Value()+'/'+(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())+')'],{visible:()=>inp4.Value()>0, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
     brd2.create('text', [()=>8+2.75*Math.cos((1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+0.5*inp5.Value())*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())), ()=>3+2.75*Math.sin((1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+0.5*inp5.Value())*2*Math.PI/(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value())), ()=>'Shar-Pie ('+inp5.Value()+'/'+(1*inp1.Value()+1*inp2.Value()+1*inp3.Value()+1*inp4.Value()+1*inp5.Value()) +')'],{visible:()=>inp5.Value()>0, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
//
     var reload = brd2.create('image', ['/assets/shuffle.svg', [10.25,8.4],[1.2, 1.2]], {opacity:1, fixed:true});
     reload.setLabel('Tap to Shuffle')
     reload.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
     reload.on('over', function () {this.label.setAttribute({visible:true});});
     reload.on('out', function () {this.label.setAttribute({visible:false});});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>1});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(2*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(2*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>2});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(3*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(3*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>3});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(4*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(4*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>4});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(5*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(5*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>5});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(6*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(6*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>6});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(7*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(7*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>7});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(8*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(8*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>8});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(9*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(9*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>9});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(10*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(10*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>10});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(11*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(11*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>11});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(12*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(12*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>12});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(13*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(13*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>13});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(14*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(14*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>14});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(15*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(15*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>15});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(16*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(16*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>18});
     brd2.create('segment', [[8,3], [()=>8+2*Math.cos(17*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2*Math.sin(17*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()))]],
     {strokeColor:'black', strokeWidth:1, dash:2, visible:()=>(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())>17});
     reload.on('down', function(){ini();type1=Math.round(Math.random()*3)+1; type2=Math.round(Math.random()*4)+1; type3=Math.round(Math.random()*4)+1;type4=Math.round(Math.random()*2)+1;type5=Math.round(Math.random()*3)+1;
      i=1;j=1;k=1;l=1;m=1;
       bd();
       dm();
       gh();
       dmt();
       sp();
     });
     //brd2.create('text', [9, -0.5, 'Breed of Dog'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(22*brd2.canvasWidth/800.)}});
     //brd2.create('text', [-0.5, 5, 'Number of Dogs'],{display:'internal', rotate:90, anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(22*brd2.canvasWidth/800.)}});
     brd2.create('text', [3, 10.85, 'Bulldog'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
     brd2.create('text', [4.5, 10.85, 'Doberman'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
     brd2.create('text', [6, 10.85, 'Greyhound'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
     brd2.create('text', [7.5, 10.85, 'Dalmatian'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
     brd2.create('text', [9, 10.85, 'Shar-Pei'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    //brd2.create('text', [1, -0.5, 'Bulldog'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    //brd2.create('text', [2, -0.5, 'Doberman'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    //brd2.create('text', [3, -0.5, 'Greyhound'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    //brd2.create('text', [4, -0.5, 'Dalmatian'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    //brd2.create('text', [5, -0.5, 'Shar-pei'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    var test = brd2.create('image', ['/assets/test.svg', [2,0.6],[1.2, 1.2]], {opacity:1, fixed:true});
    test.setLabel('Tap to Test')
    test.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    test.on('over', function () {this.label.setAttribute({visible:true});});
    test.on('out', function () {this.label.setAttribute({visible:false});});
    var correct=brd2.create('image', ['/assets/check.svg', [10.5, 5.5], [1. ,1.]], {visible: false, fixed: true});
    var wrong=brd2.create('image', ['/assets/cross.svg', [10.5, 5.5], [1. ,1.]], {visible: false, fixed: true});
    function check(){

        if( inp1.Value() == type1 && inp2.Value() == type2 && inp3.Value() == type3 && inp4.Value() == type4 && inp5.Value() == type5){

            correct.setAttribute({visible: true});
            wrong.setAttribute({visible: false});

        }

        else{
            correct.setAttribute({visible: false});
            wrong.setAttribute({visible: true});
        }
}
   test.on('down', check);
      }
}
export default Boxes;
