<template>
  <div>
    <h3 ref="what">
      What is a Pie Chart?
    </h3>
    <p class="mt-2">
      A pie chart is a visualization technique where the data is represented using a circular diagram that resembles a pie.
      It is called a pie chart for its resemblance to a pie that has been sliced.
      Each sector (known as a 'slice') of the circle (known as a 'pie') represents a sample of the data.
      The size of the slice is proportional to the size of the sample relative to whole.
    </p>
    <h3 ref="example">
      Example of a Pie Chart
    </h3>
    <p class="mt-2">
      Let's say you are at a dog exhibition while visiting a pet fair. The exhibition features a variety of dog breeds at display: 2 Bulldogs, 3 Dobermans, 4 Greyhounds, 1 Dalmatian, and 2 Shar-Peis. <br>
      Our goal is to create a pie chart showing the distribution of each dog breed relative to whole at the exhibition.
      Let's start with a whole pie and mark onto it 12 (=2+3+4+1+2 is the total sample size) equal slices.<br>
      For each dog breed, we cut out the number of slices that is equal to the number of dogs of that breed. Thus, we have 2 out of 12 slices for Bulldogs, 3 out of 12 for Dobermans, 4 out of 12 for Greyhounds, 1 out of 12 for Dalmatian, and 2 out of 12 for Shar-Peis.
    </p>
    </v-flex>
    <v-layout justify-center>
      <v-img src="/assets/PieChart.png"
             max-height="550px"
             max-width="550px"
             width="400px"
             contain
      />
    </v-layout>
    <h5 style="text-align:center">
      Figure: Example of a pie chart displaying distribution of dogs of different breeds.
    </h5>
    <h3 ref="playgraph">
      MagicGraph &vert; Understanding a Pie Chart
    </h3>
    <p>
      This MagicGraph offers a visually interactive illustration to help students understand a pie chart.
    </p>
    <h5> To Get Started </h5>
    <p>
      Let's say you are at a dog exhibition while visiting a pet fair. The exhibition features a variety of dog breeds at display:  Bulldogs, Dobermans, Greyhounds,  Dalmatians, and Shar-Peis. <br>
      This MagicGraph creates a pie chart showing the distribution of each dog breed relative to whole at the exhibition. 
    </p><p />
    <h5> To Explore</h5>
    <p>
      Tap on the shuffle button to create a different distribution of dog.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <h3 ref="playgraph2">
      MagicGraph &vert; Test Your Knowledge of Pie Chart
    </h3>
    <p>
      This MagicGraph offers a visually interactive way to test your knowledge of bar charts. Enter the total number of dogs and number of individual dogs to create the pie chart.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox2" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Angles',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Pie Chart';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Pie Chart?', img:'/assets/number-1.svg', action: () => this.goto('ca')},
      {title: 'Supplementary Angle', img:'/assets/number-2.svg', action: () => this.goto('sa')},
      {title: 'Special Remarks', img:'/assets/number-3.svg', action: () => this.goto('ra')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Boxes.box2();
  },
  metaInfo() {
  return{ title: 'Pie Chart',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
#jxgbox2 input {border-style:solid;border-radius:4px;background-color:#008CBA}
</style>
